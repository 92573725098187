import axios from'axios';    //API k liye hume chahiye axios library , uske liye hum jaenge terminal m cd client and install krege npm i axios 

const API_URL ='http://localhost:8000';
//ek function bnaya jisko app.js m api k roop m call kiya h isliye isko sath hi export bhi kiya h or api call m error handlimg bhi krni pdegi thats why use try and catch...
export const uploadFile =async(data)=>{ 
  try{
    //data bhejna h to post api lenge, pehla arguement api url jo ki backend ka url hota h (backend chll ra h port 8000 local host p) sat hi api ka end point denge jo h upload naam ka, dusra post api ki body mtlb data
    let response = await axios.post(`${API_URL}/upload`,data);   
    //axios kuchh object return krta h jo data m hoge
    return response.data;
    




  }catch(error){
    console.error('Error while calling the api', error.message);

  }


}