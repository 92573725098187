import {useRef ,useState, useEffect} from 'react';    // useRef document.getElementById, querySelector ka replace h jo virtual dom ka part h and useState bhi react ka ek hook h jo kuchh bhi store krwane k liye use krte hai and useEffects uplaod p click krne s jb file aati h to API call marne k liye use krte h 
import './App.css';
import { uploadFile } from './services/api';


function App() {
  const[file,setFile]=useState('');
  const[result, setResult]= useState('');

  const fileInputRef = useRef();
  const logo="https://i.pinimg.com/originals/16/46/24/1646243661201a0892cc4b1a64fcbacf.jpg";

  useEffect(()=>{     
   const getImage=async()=>{
    if(file){
      const data =new FormData();
      data.append("name", file.name);
      data.append("file",file);

     let response= await uploadFile(data);//API call jo ki api.js m bnai hui h, hmne idhr s (data) bhja jo udhr api.js s lenge , await isliye liya h kyuki uploadFile ek async function hai
     setResult(response.path);
    }


   }
   getImage()

  },[file])

  const onUploadClick=()=>{                           
    fileInputRef.current.click();
  }
  return (
    <div className='container'>
     <img src={logo} alt="banner" />
     <div className="wrapper">
      <h1>Simple File Sharing!</h1>
      <p>Upload and share the download link</p>

      <button onClick={()=> onUploadClick()}>Upload</button>
      <input type="file"                /*Upload button p click krne s choose file option open hoga */
      ref={fileInputRef}
      style={{display:'none'}}     
     onChange={(e)=> setFile(e.target.files[0])}/>  {/* file k case m value e.target.files m se niklti h...files ek array hota h kyuki aap multiple files ko select kr skte ho...we take files[0] becoz we select single file */}  
    
     <a href={result} target='_blank'>{result}</a>
     
     </div>
      <div>
      </div>
    </div>
  );
}

export default App;
